import React, { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
  FileOutlined,
  ApartmentOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import SvgIcon from "../../const/SvgIcon";
import { ShowMenu } from "../../utils";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#000000" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              onClick: () => {
                navigate("/");
              },
            },
            {
              key: "users",
              icon: <QuestionCircleOutlined />,
              label: "Users",
              style: { display: ShowMenu(["Users"]) },
              onClick: () => {
                navigate("/users");
              },
            },
            {
              key: "message",
              icon: <FileSearchOutlined />,
              label: "Message",
              style: { display: ShowMenu(["Message"]) },
              onClick: () => {
                navigate("/message");
              },
            },
            {
              key: "battle-management",
              icon: <QuestionCircleOutlined />,
              label: "Battle",
              style: {
                display: ShowMenu([
                  "Battle",
                  "Battle Classic Auto",
                  "Battle Popular",
                  "Battle Snake And Ladder",
                ]),
              },
              children: [
                {
                  key: "battle",
                  label: "Battle",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Battle"]) },
                  onClick: () => {
                    navigate("/battle");
                  },
                },

                {
                  key: "battle-classic-auto",
                  label: "Battle Classic Auto",
                  icon: <QuestionOutlined />,
                  style: { display: ShowMenu(["Battle Classic Auto"]) },
                  onClick: () => {
                    navigate("/battle-classic-auto");
                  },
                },
                {
                  key: "battle-popular",
                  label: "Battle Popular",
                  icon: <QuestionOutlined />,
                  style: { display: ShowMenu(["Battle Popular"]) },
                  onClick: () => {
                    navigate("/battle-popular");
                  },
                },
                {
                  key: "battle-snake-and-ladder",
                  label: "Battle Snake And Ladder",
                  icon: <QuestionOutlined />,
                  style: { display: ShowMenu(["Battle Snake And Ladder"]) },
                  onClick: () => {
                    navigate("/battle-snake-and-ladder");
                  },
                },
              ],
            },
            // {
            //   key: "battle",
            //   icon: <FileSearchOutlined />,
            //   label: "Battle",
            //   style: { display: ShowMenu(["Battle"]) },
            //   onClick: () => {
            //     navigate("/battle");
            //   },
            // },
            {
              key: "winner-request",
              icon: <ApartmentOutlined />,
              label: "Winner Request",
              style: { display: ShowMenu(["Winner Request"]) },
              onClick: () => {
                navigate("/winner-request");
              },
            },
            {
              key: "withdrawal-request",
              icon: <QuestionCircleOutlined />,
              label: "Withdrawal Request",
              style: { display: ShowMenu(["Withdrawal Request"]) },
              onClick: () => {
                navigate("/withdrawal-request");
              },
            },
            {
              key: "kyc-pending",
              icon: <QuestionCircleOutlined />,
              label: "Kyc Pending",
              style: { display: ShowMenu(["Kyc Pending"]) },
              onClick: () => {
                navigate("/kyc-pending");
              },
            },
            {
              key: "transaction",
              icon: <QuestionCircleOutlined />,
              label: "Transaction",
              style: { display: ShowMenu(["Transaction"]) },
              onClick: () => {
                navigate("/transaction");
              },
            },
            {
              key: "setting",
              icon: <QuestionCircleOutlined />,
              label: "Setting",
              style: { display: ShowMenu(["Setting"]) },
              onClick: () => {
                navigate("/setting");
              },
            },
            {
              key: "account-list",
              icon: <QuestionCircleOutlined />,
              label: "Account List",
              style: { display: ShowMenu(["Account List"]) },
              onClick: () => {
                navigate("/account-list");
              },
            },
            {
              key: "point-request",
              icon: <QuestionCircleOutlined />,
              label: "Point Request",
              style: { display: ShowMenu(["Point Request"]) },
              onClick: () => {
                navigate("/point-request");
              },
            },

            // {
            //     key: 'investor-management',
            //     icon: <QuestionCircleOutlined />,
            //     label: 'Investor Management',
            //     style: { display: ShowMenu(["investor Category", "Financials Pages", 'investor Pages']) },
            //     children: [
            //         {
            //             key: 'investor Category',
            //             label: 'Categories',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Categorys"]) },
            //             onClick: () => {
            //                 navigate("/investor-category")
            //             },

            //         },

            //         {
            //             key: 'financials',
            //             label: 'Financials',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Financials Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-financials")
            //             },
            //         },
            //         {
            //             key: 'investor',
            //             label: 'Investors Information',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-information")
            //             },
            //         },
            //         {
            //             key: 'corporate-governance',
            //             label: 'Corporate Governance',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-corporate-governance")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'seo-management',
            //     icon: <SvgIcon.seoManagementIcon />,
            //     label: 'SEO Management',
            //     style: { display: ShowMenu(["seo", 'Seo Pages']) },
            //     children: [
            //         {
            //             key: 'seo',
            //             label: 'SEO',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Seo Pages"]) },
            //             onClick: () => {
            //                 navigate("/seo-management")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'ticket-management',
            //     icon: <FileOutlined />,
            //     label: 'Ticket Management',
            //     style: { display: ShowMenu(["Ticket Management"]) },
            //     onClick: () => {
            //         navigate("/ticket-management")
            //     }
            // },
            // {
            //     key: 'hr-management',
            //     icon: <SvgIcon.HrIcon />,
            //     label: 'HR Management',
            //     style: { display: ShowMenu(["Job Applications", "Job Posts"]) },
            //     children: [
            //         {
            //             key: 'job-applications',
            //             label: 'Job Applications',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Applications"]) },
            //             onClick: () => {
            //                 navigate("job-applications")
            //             },
            //         },
            //         {
            //             key: 'job-post',
            //             label: 'Job Posts',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Posts"]) },
            //             onClick: () => {
            //                 navigate("jobs")
            //             },
            //         },

            //     ]
            // },
            // {
            //     key: 'user-management',
            //     icon: < UsergroupAddOutlined />,
            //     label: 'Users Management',
            //     style: { display: ShowMenu(["Users", "Roles"]) },
            //     children: [
            //         {
            //             key: 'users',
            //             label: 'Users',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Users"]) },
            //             onClick: () => {
            //                 navigate("/users")
            //             },
            //         },
            //         {
            //             key: 'roles',
            //             label: 'Roles',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Roles"]) },
            //             onClick: () => {
            //                 navigate("/roles")
            //             },
            //         }
            //     ]
            // },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
