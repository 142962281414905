import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import HeadingBack from "../component/HeadingBack";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function UserDetails() {
  const dispatch = useDispatch();
  const data = useParams();

  const details = useSelector((state) =>
    state?.allapi?.user_details ? state?.allapi?.user_details : {}
  );

  console.log(details);

  useEffect(() => {
    dispatch(allapiAction.getProfileDetails({ uid: data?.id }));

    return () => {};
  }, []);

  const [statusselect, setstatusselect] = useState("");
  const [statusselectreas, setstatusselectreas] = useState("");

  console.log(statusselect);
  console.log(statusselectreas);

  useEffect(() => {
    setstatusselect(details?.keyverify);
    setstatusselectreas(details?.accountId?.reason);
  }, [details]);

  const [show, setShow] = useState(false);
  const [showcupdate, setShowUpdate] = useState(false);
  const [dataimg, setdataimg] = useState("");

  const handleClose = () => {
    setdataimg("");
    setShow(false);
  };
  const handleCloseUpdate = () => {
    // setdataimg('')
    setShowUpdate(false);
  };
  const handleShow = () => {
    setShow(true);
    // setdataimg("")
  };

  const hendleimgView = (e) => {
    setdataimg(e);
    setShow(true);
  };

  const hendletosubmitVerify = (e) => {
    // verifykeyByAdmin
    setShowUpdate(true);
    // dispatch(allapiAction.verifykeyByAdmin({_id: details?._id, keyverify: e}))

    return () => {};
  };

  const hendlesubmitup = () => {
    setShowUpdate(false);
    dispatch(
      allapiAction.verifykeyByAdmin({
        _id: details?._id,
        keyverify: statusselect,
        reason: statusselectreas,
      })
    );

    return () => {};
  };

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }

  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <img
          style={{ width: "100%" }}
          src={URL.API_BASE_URL + dataimg}
          alt=""
        />

        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showcupdate} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>Approve</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-7 py-5">
            <div className="mb-10">
              <label className="form-label fw-semibold">approve:</label>

              <div>
                <select
                  className="form-select "
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-dropdown-parent="#kt_menu_637dc7729cedd"
                  data-allow-clear="true"
                  value={statusselect}
                  onChange={(e) => {
                    setstatusselect(e.target.value);
                  }}
                >
                  {/* <option></option> */}
                  <option value="pending">Pending</option>
                  <option value="reject">Rejected</option>
                  {/* <option value='complete'>In Process</option> */}
                  <option value="complete">Complete</option>
                </select>
              </div>

              {/* statusselect */}
              {/* <!--end::Input-->  */}
            </div>
          </div>
          {statusselect == "reject" && (
            <div className="px-7 py-5">
              <div className="mb-10">
                <label className="form-label fw-semibold">Reason:</label>

                <div>
                  <input
                    className="form-control bg-transparent"
                    value={statusselectreas}
                    onChange={(e) => {
                      setstatusselectreas(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdate}>
            Close
          </Button>
          <Button variant="primary" onClick={hendlesubmitup}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="tableResponsive mt-10  code-box">
        <div className="d-flex flex-column flex-column-fluid">
          {/* <!--begin::Toolbar-->  */}
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            {/* <!--begin::Toolbar container-->  */}
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              {/* <!--begin::Page title-->  */}
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                {/* <!--begin::Title-->  */}
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  User Details
                </h1>
              </div>
              {/* <!--end::Page title-->  */}
              {/* <!--begin::Actions-->  */}
            </div>
            {/* <!--end::Toolbar container-->  */}
          </div>
          {/* <!--end::Toolbar-->  */}
          {/* <!--begin::Content-->  */}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/* <!--begin::Content container-->  */}
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              {/* <!--begin::Card-->  */}
              <div className="card card-flush">
                {/* <!--begin::Card header-->  */}
                <div className="card-header mt-6">
                  {/* <!--begin::Card title-->  */}
                  <div className="card-title"></div>
                  {/* <!--end::Card title-->  */}
                  {/* <!--begin::Card toolbar-->  */}
                  <div className="card-toolbar"></div>
                </div>
                {/* <!--end::Card header-->  */}
                {/* <!--begin::Card body-->  */}
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-4">
                      <div className="row">
                        <div className="col-2">
                          <img src="" alt="" />
                        </div>
                        <div className="col-10">
                          <p>{details?.uid}</p>
                          <p>{details?.number}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <div className="col-6">Balence</div>
                        <div className="col-6">
                          {details?.walletId?.closeBal} ₹
                        </div>
                        <div className="col-6">Winning Balence</div>
                        <div className="col-6">
                          {details?.walletId?.winningBal} ₹
                        </div>
                        <div className="col-6">Total Earning</div>
                        <div className="col-6">
                          {details?.walletId?.earning} ₹
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="row">
                        <div className="col-6">Loss</div>
                        <div className="col-6">verify</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <table className="userDetailTable">
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2">
                            Kyc Doucument Details
                          </th>
                          <th className="userDetailTablet2"></th>
                        </tr>
                        <tr>
                          <td>Doucument Name</td>
                          <td>
                            {details?.accountId?.idType == "1"
                              ? "Adhaar crad"
                              : details?.accountId?.idType == "2"
                              ? "Driveing License"
                              : "Voter Id Card"}
                          </td>
                        </tr>
                        <tr>
                          <td>Doucument Number</td>
                          <td>{details?.accountId?.idNumber}</td>
                        </tr>
                        <tr>
                          <td>Doucument Image</td>
                          <td style={{ width: "50px" }}>
                            <a
                              onClick={(e) => {
                                hendleimgView(details?.accountId?.idImage);
                              }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src={
                                  URL.API_BASE_URL + details?.accountId?.idImage
                                }
                                alt=""
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Qr Image</td>
                          <td style={{ width: "50px" }}>
                            <a
                              onClick={(e) => {
                                hendleimgView(details?.accountId?.qrcode);
                              }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src={
                                  URL.API_BASE_URL + details?.accountId?.qrcode
                                }
                                alt=""
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>UPI </td>
                          <td>{details?.accountId?.upiId}</td>
                        </tr>
                        <tr>
                          <td>Paytm Wallet </td>
                          <td>{details?.accountId?.paytmWallet}</td>
                        </tr>

                        <tr>
                          <td>Verify Doucument status</td>
                          <td>
                            {" "}
                            <button
                              data-id="2209"
                              onClick={() => {
                                hendletosubmitVerify(
                                  details?.keyverify == "Active"
                                    ? "Inactive"
                                    : "Active"
                                );
                              }}
                              className={
                                details?.keyverify == "complete"
                                  ? "btn btn-sm btn-success ms-3 mt-3"
                                  : "btn btn-sm btn-danger ms-3 mt-3"
                              }
                            >
                              {details?.keyverify}
                              {/* <i className='fas fa-trash-alt'></i> */}
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-4">
                      <table className="userDetailTable">
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2">
                            Bank Account Details
                          </th>
                          <th className="userDetailTablet2"></th>
                        </tr>
                        <tr>
                          <td>Bank account holder Name</td>
                          <td>{details?.accountId?.userName}</td>
                        </tr>
                        <tr>
                          <td>Ifse Code</td>
                          <td>{details?.accountId?.ifseCode}</td>
                        </tr>
                        <tr>
                          <td>Bank Name</td>
                          <td>{details?.accountId?.bankName}</td>
                        </tr>
                        <tr>
                          <td>Account Number</td>
                          <td>{details?.accountId?.accountNumber}</td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-4">
                      <table className="userDetailTable">
                        <tr className="userDetailTablet">
                          <th className="userDetailTablet2">Other Details</th>
                          <th className="userDetailTablet2"></th>
                        </tr>
                        <tr>
                          <td>Refer Code</td>
                          <td>{details?.referCode}</td>
                        </tr>
                        <tr>
                          <td>Refer by</td>
                          <td>{details?.referBy}</td>
                        </tr>
                        {/* <tr>
                        <td>Doucument Name</td>
                        <td>v3</td>
                      </tr> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--end::Content container-->  */}
          </div>
          {/* <!--end::Content-->  */}
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
